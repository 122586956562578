/* Home page */
#Home {
    position: relative;
    width: 100%;
    height: 89vh;
    padding-top: 50px;
    background-image: url(../img/home-background.webp);
    background-repeat: no-repeat;
    background-position: top left;
    background-attachment: fixed;
    background-color: rgba(0, 0, 0, 0.8);

    #language {
        cursor: pointer;
        position: absolute;
        top: 60px;
        right: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        z-index: 3;

        &:active {
            transform: scale(.9);
        }

        .srb,
        .en {
            display: block;
            width: 100%;
            height: 100%;
            background: url(../img/language-sprites.png);
        }

        &>a.srb {
            background-position: 0 0;
        }

        &>a.en {
            background-position: 40px 0;
        }
    }

    .heading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        text-align: center;
        height: 110px;

        & h1 {
            font-size: 1.4rem;
            font-weight: bold;
            margin-top: 50px;
            margin-bottom: 0;
            letter-spacing: 5px;
            color: #FF8000;
            transition: all .3s ease;

            &:hover {
                transform: translate3d(0, -10px, 22px);
                color: #fff;
            }
        }

        .span-holder {
            min-height: 22px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }

        .home-loader span {
            color: #faebd7;
            text-shadow: 0 0 0 #faebd7;
            animation: header 1s ease-in-out infinite alternate;

            &:nth-child(2) {
                animation-delay: 0.1s;
            }

            &:nth-child(3) {
                animation-delay: 0.2s;
            }

            &:nth-child(4) {
                animation-delay: 0.3s;
            }

            &:nth-child(5) {
                animation-delay: 0.4s;
            }

            &:nth-child(6) {
                animation-delay: 0.5s;
            }

            &:nth-child(7) {
                animation-delay: 0.6s;
            }

            &:nth-child(8) {
                animation-delay: 0.7s;
            }

            &:nth-child(9) {
                animation-delay: 0.8s;
            }

            &:nth-child(10) {
                animation-delay: 0.9s;
            }

            &:nth-child(11) {
                animation-delay: 1s;
            }

            &:nth-child(12) {
                animation-delay: 1.1s;
            }

            &:nth-child(13) {
                animation-delay: 1.2s;
            }

            &:nth-child(14) {
                animation-delay: 1.3s;
            }

            &:nth-child(15) {
                animation-delay: 1.4s;
            }

            &:nth-child(16) {
                animation-delay: 1.5s;
            }
        }

        @keyframes header {
            to {
                text-shadow: 20px 0 70px #FF8000;
                color: #FF8000;
            }
        }

        h3.span {
            margin: 0 !important;
            font-size: .8rem;
            letter-spacing: 8px;
            font-weight: 100;
            cursor: cell;
        }

        span:hover {
            color: #FF8000;
            font-weight: 300;
            font-size: 1.1em;
        }
    }

    /* Moon */
    #moon {
        position: absolute;
        top: -100px;
        left: 40%;
        opacity: 0;
        transform: scale(.3);
        width: 150px;
        height: 150px;
        border-radius: 50%;
        overflow: hidden;
        background: url(../img/moon-sprite.png) center/cover no-repeat;
        background-position: 0px 0px;
    }

    .coming {
        cursor: pointer;
        animation-name: moon_coming;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-delay: 1.3s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
    }

    @keyframes moon_coming {
        0% {
            transform: scale(.3);
            top: -100px;
            left: 40%;
            opacity: .2;
        }

        25% {
            transform: scale(.4);
            top: -100px;
            left: 70%;
            opacity: .4;
        }

        65% {
            top: 230px;
            left: 10px;
            opacity: .9;
        }

        90% {
            transform: scale(1);
            top: 164px;
            left: 50%;
            transform: translateX(-50%);
            background-position: 0px 0px;
            opacity: 1;
        }

        99.9% {
            background-position: 0px 0px;
        }

        100% {
            top: 164px;
            left: 50%;
            transform: translateX(-50%);
            background-position: -150px 0px;
            opacity: 1;
        }
    }

    .outgoing {
        cursor: none;
        animation-name: moon_outgoing;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
    }

    @keyframes moon_outgoing {
        0% {
            top: 164px;
            left: 50%;
            transform: translateX(-50%);
            opacity: 1;
        }

        40% {
            top: 230px;
            left: 40px;
        }

        100% {
            top: -15px;
            left: 50%;
            transform: translateX(-50%) scale(.6);
            opacity: 1;
        }
    }

    /**** Stars ***/
    .stars,
    .stars:after,
    .stars:before {
        position: fixed;
        width: 0.4em;
        height: 0.4em;
        border-radius: 50%;
        background-color: whitesmoke;
        animation: blink 5s infinite ease-in-out;
    }

    .stars {

        &:after,
        &:before {
            content: "";
            display: block;
        }
    }

    .s1 {
        top: 8%;
        left: 1%;

        &:after {
            animation-delay: 0.5s;
            top: 18%;
            left: 75%;
        }

        &:before {
            top: 28%;
            left: 30%;
        }
    }

    .s2 {
        animation-delay: .25s;
        top: 13%;
        left: 68%;
        font-size: 0.6em;

        &:after {
            top: 28%;
            left: 90%;
        }

        &:before {
            animation-delay: 0.5s;
            top: 16%;
            left: 35%;
        }
    }

    .s3 {
        animation-delay: .5s;
        top: 12%;
        left: 41%;
        font-size: 0.5em;

        &:after {
            animation-delay: 0.75s;
            top: 18%;
            left: 18%;
        }

        &:before {
            animation-delay: .25s;
            top: 35%;
            left: 12%;
        }
    }

    .s4 {
        top: 28%;
        left: 3%;
        font-size: 0.3em;

        &:after {
            animation-delay: .5s;
            top: 25%;
            left: 30%;
        }

        &:before {
            top: 25em;
            left: 22em;
        }
    }

    @keyframes blink {
        0% {
            opacity: .85;
        }

        25% {
            opacity: 0.4;
        }

        50% {
            opacity: .85;
        }

        75% {
            opacity: 0.4;
        }

        100% {
            opacity: .85;
        }
    }

    /*** welcome-message ***/
    #welcome-message {
        width: 95%;
        min-height: 200px;
        margin: 20px auto 0;
        text-indent: 60px;
        font-size: 1.1rem;
        text-align: left;
        letter-spacing: 1px;
        line-height: 1.3;

        .a-link {
            display: block;
            width: 150px;
            height: 30px;
            line-height: 30px;
            margin: 10px auto;
            background-color: rgba(0, 0, 0, .5);
            color: #FF8000;
            border: 1px solid #FF8000;
            border-radius: 5px;
            text-indent: 0;
            text-align: center;
            cursor: pointer;
            transition: all .2s;

            &:hover {
                color: #ddd;
                background-color: rgba(255, 128, 0, .5);
                border-color: #ddd;
            }

            &:active {
                position: relative;
                top: 2px;
                left: 2px;
            }
        }
    }

    /* Astronaut*/
    #astronaut {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 230px;
        height: 230px;
        margin: 0px auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #astronaut>img {
        display: block;
        width: 20%;
        height: auto;
        margin: 0 auto;
        transition: all 8s;
    }
}

/************ Media ************/
@media all and (min-width: 576px) {
    .heading h1 {
        font-size: 1.5rem !important;
    }

    h3.span {
        font-size: 1rem !important;
    }

    /*Moon*/
    @keyframes moon_coming {
        0% {
            transform: scale(.3);
            top: -100px;
            left: 40%;
            opacity: .2;
        }

        25% {
            transform: scale(.4);
            top: -100px;
            left: 80%;
            opacity: .4;
        }

        65% {
            top: 230px;
            left: 10px;
            opacity: .9;
        }

        90% {
            transform: scale(1);
            top: 164px;
            left: 50%;
            transform: translateX(-50%);
            background-position: 0px 0px;
            opacity: 1;
        }

        99.9% {
            background-position: 0px 0px;
        }

        100% {
            top: 164px;
            left: 50%;
            transform: translateX(-50%);
            background-position: -150px 0px;
            opacity: 1;
        }
    }

    #welcome-message {
        min-height: 210px !important;
    }

    .a-link {
        margin: 40px auto !important;
    }
}

@media all and (min-width: 768px) {
    #Home {
        height: 86vh;
        padding-top: 60px;

        /* Astronaut*/
        #astronaut {
            width: 300px;
            height: 300px;
        }

        #astronaut>img {
            width: 30%;
        }
    }

    .heading h1 {
        width: 350px;
        font-size: 1.7rem !important;
    }

    h3.span {
        font-size: 1.1rem !important;
    }

    #welcome-message {
        width: 80% !important;
        min-height: 165px !important;
        margin: 70px auto 0 !important;
        text-indent: 60px !important;
        font-size: 1.1rem !important;
        text-align: left !important;
        letter-spacing: 1px !important;
        line-height: 1.3 !important;
    }

    /*Moon*/
    #Home #moon {
        top: -116px;
        left: 50%;
    }

    @keyframes moon_coming {
        0% {
            transform: scale(.3);
            top: -116px;
            left: 50%;
            opacity: .2;
        }

        25% {
            transform: scale(.4);
            top: -116px;
            left: 85%;
            opacity: .4;
        }

        65% {
            top: 230px;
            left: 10px;
            opacity: .9;
        }

        90% {
            transform: scale(1);
            top: 164px;
            left: 50%;
            transform: translateX(-50%);
            background-position: 0px 0px;
            opacity: 1;
        }

        99.9% {
            background-position: 0px 0px;
        }

        100% {
            top: 164px;
            left: 50%;
            transform: translateX(-50%);
            background-position: -150px 0px;
            opacity: 1;
        }
    }

    @keyframes moon_outgoing {
        0% {
            top: 164px;
            left: 50%;
            transform: translateX(-50%);
            opacity: 1;
        }

        40% {
            top: 230px;
            left: 40px;
        }

        100% {
            top: 0px;
            left: 50%;
            transform: translateX(-50%) scale(.6);
            opacity: 1;
        }
    }
}

@media all and (min-width: 992px) {

    /*Moon*/
    #Home #moon {
        left: 55%;
    }

    @keyframes moon_coming {
        0% {
            transform: scale(.3);
            top: -116px;
            left: 50%;
            opacity: .2;
        }

        25% {
            transform: scale(.4);
            top: -116px;
            left: 89%;
            opacity: .4;
        }

        65% {
            top: 230px;
            left: 10px;
            opacity: .9;
        }

        90% {
            transform: scale(1);
            top: 164px;
            left: 50%;
            transform: translateX(-50%);
            background-position: 0px 0px;
            opacity: 1;
        }

        99.9% {
            background-position: 0px 0px;
        }

        100% {
            top: 164px;
            left: 50%;
            transform: translateX(-50%);
            background-position: -150px 0px;
            opacity: 1;
        }
    }

    #welcome-message {
        width: 60% !important;
    }
}

@media all and (min-width: 1200px) {

    /*Moon*/
    #Home #moon {
        left: 70%;
    }

    @keyframes moon_coming {
        0% {
            transform: scale(.3);
            top: -116px;
            left: 70%;
            opacity: .2;
        }

        25% {
            transform: scale(.4);
            top: -116px;
            left: 91%;
            opacity: .4;
        }

        65% {
            top: 230px;
            left: 10px;
            opacity: .9;
        }

        90% {
            transform: scale(1);
            top: 164px;
            left: 50%;
            transform: translateX(-50%);
            background-position: 0px 0px;
            opacity: 1;
        }

        99.9% {
            background-position: 0px 0px;
        }

        100% {
            top: 164px;
            left: 50%;
            transform: translateX(-50%);
            background-position: -150px 0px;
            opacity: 1;
        }
    }

    #welcome-message {
        width: 50% !important;
    }
}

@media all and (min-width: 1400px) {
    #Home {
        padding-top: 50px;

        .heading {
            height: auto;
        }
    }

    .heading h1 {
        font-size: 2.4rem !important;
    }

    h3.span {
        font-size: 1.2rem !important;
    }

    /*Moon*/
    #Home #moon {
        left: 75%;
    }

    @keyframes moon_coming {
        0% {
            transform: scale(.3);
            top: -116px;
            left: 80%;
            opacity: .2;
        }

        25% {
            transform: scale(.4);
            top: -116px;
            left: 92%;
            opacity: .4;
        }

        65% {
            top: 230px;
            left: 10px;
            opacity: .9;
        }

        90% {
            transform: scale(1);
            top: 164px;
            left: 50%;
            transform: translateX(-50%);
            background-position: 0px 0px;
            opacity: 1;
        }

        99.9% {
            background-position: 0px 0px;
        }

        100% {
            top: 164px;
            left: 50%;
            transform: translateX(-50%);
            background-position: -150px 0px;
            opacity: 1;
        }
    }

    #welcome-message {
        width: 40% !important;
    }
}

@media all and (min-width: 1800px) {
    #Home {
        background-size: cover;
    }
}