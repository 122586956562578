.impression-page {
    width: 100%;

    .impression-user-img {
        width: 80px;
    }

    input.impression-input {
        font-size: 18px;
        text-align: left;
        margin: 0 auto;
        padding: 0;
        color: var(--gold);
        background-color: rgba(0, 0, 0, .1);
        border: none;

        &:focus {
            color: var(--white);
            background-color: rgba(0, 0, 0, .1);
            box-shadow: none;
        }

        &::selection {
            color: var(--gold);
            background-color: rgba(0, 0, 0, .1);
        }

        &::placeholder {
            color: var(--grey);
        }
    }

    label.label-comment {
        display: block;
        text-align: left;
    }

    textarea {
        color: var(--gold);
        background-color: rgba(0, 0, 0, .1);
        border-color: var(--grey);

        &:focus {
            color: var(--white);
            background-color: rgba(0, 0, 0, .1);
            border-color: transparent !important;
            box-shadow: none;
        }

        &:focus-visible {
            border-color: var(--gold);
            outline: none;
        }

        &::selection {
            color: var(--gold);
            background-color: rgba(0, 0, 0, .1);
        }

        &::placeholder {
            color: var(--grey);
        }
    }
}