/* Navigation */
.main-header {
    position: relative;
    z-index: 2;
    .logo-social {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        justify-content: space-around;
        background-color: rgba(0, 0, 0, .8);

        .logo {
            display: block;
            width: 50px;
            height: 50px;
            background: url(../img/logo.png) center/cover no-repeat;
            text-indent: -9999px;
        }

        .social-holder {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            overflow: hidden;

            li>a {
                display: block;
                width: 40px;
                height: 40px;
                background-image: url(../img/sprite.png);
                text-indent: -9999px;
            }

            /***************************************************
                                  Facebook
            ***************************************************/
            #facebook {
                background-position: 0 -40px;
                position: relative;
                animation: facebook 4s linear 0s 1;
                transition: background-position .1s;

                &:hover {
                    background-position: 0 0;
                }

                &:active {
                    top: 2px;
                    left: 2px;
                }
            }

            @keyframes facebook {
                0% {
                    background-position: 0 0;
                    left: 0;
                    top: 0;
                }

                8% {
                    left: 50px;
                    top: 0;
                }

                24% {
                    left: -50px;
                    top: 0;
                }

                32% {
                    left: 0;
                    top: 0;
                }

                95% {
                    background-position: 0 0;
                    left: 0;
                    top: 0;
                }

                100% {
                    background-position: 0 -40px;
                    left: 0;
                    top: 0;
                }
            }

            /***************************************************
                                  Viber
            ***************************************************/
            #viber {
                background-position: -40px -40px;
                position: relative;
                animation: viber 4s linear 1.3s 1;
                transition: background-position .1s;

                &:hover {
                    background-position: -40px 0;
                }

                &:active {
                    top: 2px;
                    left: 2px;
                }
            }

            @keyframes viber {
                0% {
                    background-position: -40px 0;
                    left: 0;
                    top: 0;
                }

                8% {
                    left: 50px;
                    top: 0;
                }

                24% {
                    left: -50px;
                    top: 0;
                }

                32% {
                    left: 0;
                    top: 0;
                }

                95% {
                    background-position: -40px 0;
                    left: 0;
                    top: 0;
                }

                100% {
                    background-position: -40px -40px;
                    left: 0;
                    top: 0;
                }
            }

            /***************************************************
                                  GitHub
            ***************************************************/
            #github {
                background-position: -80px -40px;
                position: relative;
                animation: github 4s linear 2.6s 1;
                transition: background-position .1s;

                &:hover {
                    background-position: -80px 0;
                }

                &:active {
                    top: 2px;
                    left: 2px;
                }
            }

            @keyframes github {
                0% {
                    background-position: -80px 0;
                    left: 0;
                    top: 0;
                }

                8% {
                    left: 50px;
                    top: 0;
                }

                24% {
                    left: -50px;
                    top: 0;
                }

                32% {
                    left: 0;
                    top: 0;
                }

                95% {
                    background-position: -80px 0;
                    left: 0;
                    top: 0;
                }

                100% {
                    background-position: -80px -40px;
                    left: 0;
                    top: 0;
                }
            }

            /***************************************************
                                  Discord
            ***************************************************/
            #discord {
                background-position: -120px -40px;
                position: relative;
                animation: discord 4s linear 3.9s 1;
                transition: background-position .1s;

                &:hover {
                    background-position: -120px 0;
                }

                &:active {
                    top: 2px;
                    left: 2px;
                }
            }

            @keyframes discord {
                0% {
                    background-position: -120px 0;
                    left: 0;
                    top: 0;
                }

                8% {
                    left: 50px;
                    top: 0;
                }

                24% {
                    left: -50px;
                    top: 0;
                }

                32% {
                    left: 0;
                    top: 0;
                }

                95% {
                    background-position: -120px 0;
                    left: 0;
                    top: 0;
                }

                100% {
                    background-position: -120px -40px;
                    left: 0;
                    top: 0;
                }
            }

            /***************************************************
                                  Linkedin
            ***************************************************/
            #linkedin {
                background-position: -160px -40px;
                position: relative;
                animation: linkedin 4s linear 5.2s 1;
                transition: background-position .1s;

                &:hover {
                    background-position: -160px 0;
                }

                &:active {
                    top: 2px;
                    left: 2px;
                }
            }

            @keyframes linkedin {
                0% {
                    background-position: -160px 0;
                    left: 0;
                    top: 0;
                }

                8% {
                    left: 50px;
                    top: 0;
                }

                24% {
                    left: -50px;
                    top: 0;
                }

                32% {
                    left: 0;
                    top: 0;
                }

                95% {
                    background-position: -160px 0;
                    left: 0;
                    top: 0;
                }

                100% {
                    background-position: -160px -40px;
                    left: 0;
                    top: 0;
                }
            }

            /***************************************************
                                   Twitter
            ***************************************************/
            #twitter {
                background-position: -200px -40px;
                position: relative;
                animation: twitter 4s linear 6.5s 1;
                transition: background-position .1s;

                &:hover {
                    background-position: -200px 0;
                }

                &:active {
                    top: 2px;
                    left: 2px;
                }
            }

            @keyframes twitter {
                0% {
                    background-position: -200px 0;
                    left: 0;
                    top: 0;
                }

                8% {
                    left: 50px;
                    top: 0;
                }

                24% {
                    left: -50px;
                    top: 0;
                }

                32% {
                    left: 0;
                    top: 0;
                }

                95% {
                    background-position: -200px 0;
                    left: 0;
                    top: 0;
                }

                100% {
                    background-position: -200px -40px;
                    left: 0;
                    top: 0;
                }
            }

            /***************************************************
                                  Codepen
            ***************************************************/
            #codepen {
                background-position: -240px -40px;
                position: relative;
                animation: codepen 4s linear 7.8s 1;
                transition: background-position .1s;

                &:hover {
                    background-position: -240px 0;
                }

                &:active {
                    top: 2px;
                    left: 2px;
                }
            }

            @keyframes codepen {
                0% {
                    background-position: -240px 0;
                    left: 0;
                    top: 0;
                }

                8% {
                    left: 50px;
                    top: 0;
                }

                24% {
                    left: -50px;
                    top: 0;
                }

                32% {
                    left: 0;
                    top: 0;
                }

                95% {
                    background-position: -240px 0;
                    left: 0;
                    top: 0;
                }

                100% {
                    background-position: -240px -40px;
                    left: 0;
                    top: 0;
                }
            }

        }

        /********** Hamburger menu **********/
        #hamburger-menu,
        #close-hamburger {
            position: absolute;
            bottom: -50px;
            right: 5px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }

        #hamburger-menu {
            background: url(../img/hamburger.svg) center/cover no-repeat;
            background-color: #555;
            z-index: 2;

            &:hover {
                cursor: pointer;
                background-color: #FF8000;
            }
        }

        #close-hamburger {
            background: url(../img/close.svg) center/cover no-repeat;
            background-color: #555;
            z-index: 3;

            &:hover {
                cursor: pointer;
                background-color: #FF8000;
            }
        }
    }

    /************* Main Menu ***************/
    .main-nav {
        display: none;
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        z-index: 2;

        .main-menu {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            background-color: rgba(0, 0, 0, .1);

            li {
                width: 50%;
            }

            li>a {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                width: 100%;
                height: 30px;
                margin: 0 auto;
                line-height: 30px;
                text-decoration: none;
                text-align: center;
                color: var(--gold);
                font-size: 0.8rem;
                border: 1px solid var(--grey);
                border-radius: 25px;
                background-color: var(--black);
                box-shadow: 7px 7px 5px 0px rgba(40, 40, 40, 0.75);
                -moz-box-shadow: 7px 7px 5px 0px rgba(40, 40, 40, 0.75);
                box-shadow: 7px 7px 5px 0px rgba(40, 40, 40, 0.75);
                transition: color .5s;

                &:hover {
                    color: white;
                    border: 1px groove #FF8000;
                }

                &:active {
                    top: 2px;
                    left: 2px;
                    color: #8a8a8a;
                }
            }

            /********** Menu buttons **********/
            #home {
                z-index: 2;
                animation: home .2s linear .2s 1 backwards;
            }

            @keyframes home {
                0% {
                    color: white;
                    left: 0;
                    top: 0px;
                }

                50% {
                    left: 0px;
                    top: 30px;
                }

                100% {
                    color: #FF8000;
                    left: 0px;
                    top: 0px;
                }
            }

            #about-me {
                z-index: 2;
                animation: about-me .2s linear .4s 1 backwards;
            }

            @keyframes about-me {
                0% {
                    color: white;
                    left: 0;
                    top: 0px;
                }

                50% {
                    left: 0px;
                    top: 30px;
                }

                100% {
                    color: #FF8000;
                    left: 0px;
                    top: 0px;
                }
            }

            #skills {
                z-index: 2;
                animation: skills .2s linear .6s 1 backwards;
            }

            @keyframes skills {
                0% {
                    color: white;
                    left: 0;
                    top: 0px;
                }

                50% {
                    left: 0px;
                    top: 30px;
                }

                100% {
                    color: #FF8000;
                    left: 0px;
                    top: 0px;
                }
            }

            #projects {
                z-index: 1;
                animation: projects .2s linear .8s 1 backwards;
            }

            @keyframes projects {
                0% {
                    color: white;
                    left: 0;
                    top: 0px;
                }

                50% {
                    left: 0px;
                    top: 30px;
                }

                100% {
                    color: #FF8000;
                    left: 0px;
                    top: 0px;
                }
            }

            #contact {
                z-index: 1;
                animation: contact .2s linear 1s 1 backwards;
            }

            @keyframes contact {
                0% {
                    color: white;
                    left: 0;
                    top: 0px;
                }

                50% {
                    left: 0px;
                    top: 30px;
                }

                100% {
                    color: #FF8000;
                    left: 0px;
                    top: 0px;
                }
            }

            #comments {
                z-index: 1;
                animation: comments .2s linear 1.2s 1 backwards;
            }

            @keyframes comments {
                0% {
                    color: white;
                    left: 0;
                    top: 0px;
                }

                50% {
                    left: 0px;
                    top: 30px;
                }

                100% {
                    color: #FF8000;
                    left: 0px;
                    top: 0px;
                }
            }
        }
    }
}

@media all and (min-width: 576px) {
    .social-holder {
        overflow: visible;
    }
}

@media all and (min-width: 768px) {
    .logo {
        width: 80px !important;
        height: 80px !important;
    }

    #hamburger-menu {
        display: none;
    }

    .main-nav {
        display: block !important;
        top: 80px !important;
    }

    .main-menu {
        flex-direction: row !important;
    }

    .main-menu>li {
        width: 90px !important;
    }

    .main-menu>li>a {
        height: 30px !important;
        line-height: 30px !important;
        font-size: 1rem !important;
    }
}

@media all and (min-width: 1200px) {
    .logo-social {
        justify-content: space-between !important;
    }

    .logo {
        margin-left: 50px !important;
    }

    .social-holder {
        margin-right: 50px !important;
    }

    .main-menu {
        justify-content: flex-end !important;
        margin-right: 10px !important;
    }
}