/* Project page */
.projects-page {
    overflow-x: hidden;

    .intro {
        width: 50%;
        margin: 50px auto 16px;
        text-align: center;
        box-shadow: 0 0 10px 3px rgba(0, 0, 0, .5);
        border-bottom: 1px solid var(--grey);

        h1 {
            text-shadow: 2px 3px 2px var(--grey);
        }
    }

    .timeline {
        ul {
            padding: 0;

            li {
                position: relative;
                list-style: none;
                width: 6px;
                margin-left: 20px;
                padding-top: 6px;
                background-color: var(--grey);
                box-shadow: 0 0 10px 2px var(--grey);

                &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: inherit;
                }

                div {
                    position: relative;
                    bottom: 0;
                    width: calc(100vw - 100px);
                    padding: 16px;
                    background-color: rgba(0, 0, 0, .3);
                    border-radius: 5px;
                    box-shadow: 0 10px 15px 2px var(--grey);

                    &::before {
                        content: '';
                        position: absolute;
                        bottom: 7px;
                        width: 0;
                        height: 0;
                        border-style: solid;
                    }

                    p {
                        margin: 0;
                    }
                }

                &:nth-child(odd) div {
                    left: 40px;
                }

                &:nth-child(odd) div::before {
                    left: -15px;
                    border-width: 8px 16px 8px 0;
                    border-color: transparent var(--gold) transparent transparent;
                }

                &:nth-child(even) div {
                    left: 40px;
                }

                &:nth-child(even) div h2 {
                    text-align: right;
                }

                &:nth-child(even) div::before {
                    left: -15px;
                    border-width: 8px 16px 8px 0;
                    border-color: transparent var(--gold) transparent transparent;
                }

                &::after {
                    transition-property: background;
                    transition-duration: 0.5s;
                    transition-timing-function: ease-in-out;
                }

                &.in-view::after {
                    background-color: var(--gold);
                }

                div {
                    visibility: hidden;
                    opacity: 0;
                    transition: all .5s ease-in;
                }

                &:nth-child(odd) div {
                    transform: translateX(200px);
                }

                &:nth-child(even) div {
                    transform: translateX(-200px);
                }

                &.in-view div {
                    transform: none;
                    visibility: visible;
                    opacity: 1;
                }

                &.not-in-view div {
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }
    }
}

@media all and (min-width: 576px) {
    .projects-page {
        .timeline {
            ul {
                li {
                    div {
                        width: 450px;
                    }

                    &:nth-child(odd) div {
                        left: 48px;
                    }

                    &:nth-child(even) div {
                        left: 48px;
                    }
                }
            }
        }
    }
}

@media all and (min-width: 768px) {
    .projects-page {
        .timeline {
            ul {
                li {
                    margin: 0 auto;

                    div {
                        width: 300px;
                    }

                    &:nth-child(even) div {
                        left: -348px;
                    }

                    &:nth-child(even) div::before {
                        left: unset;
                        right: -15px;
                        border-width: 8px 0 8px 16px;
                        border-color: transparent transparent transparent var(--gold);
                    }
                }
            }
        }
    }
}

@media all and (min-width: 992px) {
    .projects-page {
        .timeline {
            ul {
                li {
                    div {
                        width: 400px;
                    }

                    &:nth-child(even) div {
                        left: -448px;
                    }
                }
            }
        }
    }
}