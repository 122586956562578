/* Skills page */
.skills {
    position: relative;

    &::before {
        content: "";
        background-image: url(../img/skills-background.webp);
        background-repeat: no-repeat;
        background-position: center;
        background-attachment: fixed;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: .9;
    }

    .welcome {
        display: flex;
        justify-content: center;
        margin-top: 50px;

        .left {
            background-image: url(../img/left-rocket.png);
            background-repeat: no-repeat;
            background-size: 70px 30px;
            background-position-x: left;
            width: 70px;
            height: 30px;
            opacity: .9;
        }

        .central {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 200px;
            background-color: rgba(230, 230, 230, 0.3);
            color: #FF8000;
            text-shadow: 4px 4px 2px rgba(150, 150, 150, 1);
            border-radius: 30px;
            padding: 0;
            margin: 0 3px;
            opacity: 0.6;
            transition-timing-function: linear;
            transition: all 4s;

            &:hover {
                width: 70%;
                opacity: 1;
            }

            h1 {
                font-size: 1.2rem;
                margin: 0;
                letter-spacing: 1px;
                transition: letter-spacing 5s;

                &:hover {
                    letter-spacing: 5px;
                }
            }
        }

        .right {
            background-image: url(../img/right-rocket.png);
            background-repeat: no-repeat;
            background-size: 70px 30px;
            background-position-x: right;
            width: 70px;
            height: 30px;
            opacity: .9;
        }
    }

    .moon {
        width: 50px;
        height: 50px;
        background-image: url(../img/moon.png);
        background-repeat: no-repeat;
        background-size: 50px 50px;
        margin: 10px auto 0;
        opacity: .7;
        transition: opacity 1s;

        &:hover {
            opacity: 1;
        }
    }

    .planet-holder {
        display: flex;
        height: 60px;
        opacity: 0;
        transition-timing-function: ease-out;
        transition: opacity 2s;

        &:hover {
            opacity: .9;
        }

        .jupiter {
            width: 50px;
            height: 50px;
            background-image: url(../img/jupiter.png);
            background-repeat: no-repeat;
            background-size: 50px 50px;
            margin: 0 auto;
            transition: opacity 1s;
        }

        .saturn {
            width: 50px;
            height: 50px;
            background-image: url(../img/saturn.png);
            background-repeat: no-repeat;
            background-size: 50px 50px;
            margin: 0 auto;
            transition: opacity 1s;
        }
    }

    /************ Holder ***********/
    .holder {
        // padding: 20px 0;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 20px;

        .box {
            width: 300px;
            height: 150px;
            border-top: 1px solid white;
            border-left: 1px solid white;
            border-radius: 10px;
            -webkit-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);
            -moz-box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);
            box-shadow: 7px 7px 5px 0px rgba(50, 50, 50, 0.75);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            background-image: url(../img/skills-sprites.webp);
            transition: width 1s, height 1s;

            &:nth-of-type(2n+1) {
                position: relative;
                animation: rotate-left 1s linear 0s 1 alternate;
            }

            @keyframes rotate-left {
                0% {
                    transform: rotate(-90deg);
                    left: -150px;
                    top: -220px;
                }

                100% {
                    transform: rotate(0deg);
                    left: 0px;
                    top: 0px;
                }
            }

            &:nth-of-type(2n+2) {
                position: relative;
                animation: rotate-right 1s linear 0s 1 alternate;
            }

            @keyframes rotate-right {
                0% {
                    transform: rotate(90deg);
                    right: -150px;
                    top: -220px;
                }

                100% {
                    transform: rotate(0deg);
                    right: 0px;
                    top: 0px;
                }
            }

            a {
                position: relative;
                display: block;
                border-radius: 0 0 10px 10px;
                width: 100%;
                text-decoration: none;
                text-align: center;
                font-size: 22px;
                font-weight: bold;
                color: black;
                padding: 10px 0;
                background-color: rgba(255, 255, 255, 0.7);
                transition: width 1s, height 1s;

                &:hover {
                    background-color: rgba(28, 56, 165, 0.7);
                    color: white;
                }

                &:active {
                    top: 2px;
                    left: 2px;
                }
            }
        }

        /**********Skill Image Sprites **********/
        #react {
            background-position: -300px -300px;
        }

        #redux {
            background-position: -600px -300px;
        }

        #javascript {
            background-position: 0 -300px;
        }

        #ajax {
            background-position: -600px -900px;
        }

        #node {
            background-position: -600px -600px;
        }

        #php {
            background-position: 0 -450px;
        }

        #mysql {
            background-position: -300px -450px;
        }

        #mongo {
            background-position: -300px -600px;
        }

        #tailwind {
            background-position: 0 -750px;
        }

        #sass {
            background-position: -600px -750px;
        }

        #bootstrap {
            background-position: -300px -150px;
        }

        #css {
            background-position: -300px 0;
        }

        #html {
            background-position: 0 0;
        }

        #git {
            background-position: -600px -450px;
        }

        #flexbox {
            background-position: -600px 0;
        }

        #contact-form {
            background-position: -600px -150px;
        }

        #responsive {
            background-position: 0 -150px;
        }

        #seo {
            background-position: -300px -750px;
        }

        #wordpress {
            background-position: 0 -600px;
        }

        #affinity {
            background-position: 0 -900px;
        }

        #hosting {
            background-position: -300px -900px;
        }

        #english {
            background-position: 0 -1050px;
        }
    }
}

/******************************* Media *********************************/
@media all and (min-width: 576px) {
    .central {
        h1 {
            letter-spacing: 5px !important;

            &:hover {
                letter-spacing: 15px !important;
            }
        }
    }

    .holder {
        gap: 30px !important;
    }
}

@media all and (min-width: 768px) {
    .central {
        h1 {
            padding: 0 0 0 15px !important;
            letter-spacing: 15px !important;

            &:hover {
                letter-spacing: 25px !important;
            }
        }
    }
}

@media all and (min-width: 992px) {
    .central {
        h1 {
            &:hover {
                letter-spacing: 35px !important;
            }
        }
    }

    .holder {
        gap: 35px !important;
    }
}

@media all and (min-width: 1200px) {
    .holder {
        gap: 40px !important;
    }

    .box {
        &:nth-of-type(3n+1) {
            position: relative !important;
            animation: rotate-left 1s linear 0s 1 alternate !important;
        }

        &:nth-of-type(3n+3) {
            position: relative !important;
            animation: rotate-right 1s linear 0s 1 alternate !important;
        }

        &:nth-of-type(3n+2) {
            position: relative !important;
            animation: rotate-middle 1s linear 0s 1 alternate !important;
        }

        @keyframes rotate-middle {
            0% {
                top: -220px;
            }

            25% {
                transform: rotate(10deg);
                top: -165px;
            }

            75% {
                transform: rotate(-10deg);
                top: -55px;
            }

            100% {
                transform: rotate(0deg);
                right: 0px;
                top: 0px;
            }
        }
    }
}

@media all and (min-width: 1400px) {
    .central {
        &:hover {
            width: 80% !important;
        }

        h1 {
            &:hover {
                letter-spacing: 40px !important;
            }
        }
    }
}

@media all and (min-width: 1800px) {
    .skills {
        &::before {
            background-size: cover;
        }
    }
}