.verification-page {
    width: 100%;
    height: 95vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .form-container {
        h1 {
            margin: 20px 0;
            text-shadow: 5px 2px 2px var(--grey);

            span {
                background-color: var(--black);
                color: var(--silver);
                padding: 0.2rem 1rem;
            }
        }

        label {
            margin: 0 10px;
            color: var(--silver);
        }

    }

    .element-holder {
        margin: 10px;
        padding: 10px;
        width: 320px;
        height: 320px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, .8);

        .element {
            width: 100%;
            height: 100%;
            border: 1px solid var(--black);
            box-shadow: 0 0 10px 2px rgba(0, 0, 0, .8);
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--silver);
            text-shadow: 0 0 5px var(--black);
            border-radius: 5px;

            &:hover {
                cursor: pointer;
                box-shadow: inset 0 0 100px 5px var(--black);
            }
        }
    }
}

/**************************************
            @media Queries
**************************************/

@media all and (min-width: 576px) {
    .verification-page {
        .form-container {
            h1 {
                margin: 20px;
            }
        }
    }
}