.contact-page {
    height: 89vh;

    .row-contact {
        height: 100%;

        .contact-holder {
            position: relative;
            height: 100%;
            padding-top: 50px;
            background-image: url(../img/matrix.png);
            background-size: auto;
            animation: contact-background-matrix 100s linear infinite;

            @keyframes contact-background-matrix {
                0% {
                    background-position: 0 0;
                    top: 0;
                    left: 0;
                }

                100% {
                    background-position: 0 1224px;
                    left: 0;
                    bottom: 0;
                }
            }

            #contact-form {
                height: 100%;
                overflow: auto;

                .img-holder {
                    img {
                        display: block;
                        max-width: 50%;
                        margin: 15px auto;
                    }
                }

                .contact-text {
                    font-size: 32px !important;
                    color: #fff;
                    background-color: rgba(0, 0, 0, .9);
                    border: 1px solid #fff;
                    animation: contact-text-move 1.9s linear;
                }

                @keyframes contact-text-move {
                    0% {
                        top: -550px;
                    }

                    90% {
                        left: 0px;
                        top: 0px;
                    }

                    95% {
                        transform: scale(0);
                    }

                    100% {
                        transform: scale(1);
                    }
                }

                .form-group {
                    position: relative;

                    label {
                        position: relative;
                        text-align: left;
                        margin-left: 5%;
                        color: #fff;
                        background-color: rgba(0, 0, 0, .6);
                        z-index: 2;

                        span {
                            color: red;
                        }
                    }

                    input,
                    textarea {
                        color: var(--matrix-green);
                        background-color: rgba(0, 0, 0, .8);
                        border-color: rgba(0, 204, 0, .8);

                        &:focus {
                            color: var(--white);
                            background-color: rgba(0, 0, 0, .9);
                            border-color: transparent !important;
                            box-shadow: 0 0 0 0.10rem var(--matrix-green);
                        }

                        &::placeholder {
                            color: var(--silver);
                        }
                    }

                    #first_name {
                        animation: SenderFirstName-move 1.7s linear;
                    }

                    @keyframes SenderFirstName-move {
                        0% {
                            top: -550px;
                        }

                        90% {
                            left: 0px;
                            top: 0px;
                        }

                        95% {
                            transform: scale(0);
                        }

                        100% {
                            transform: scale(1);
                        }
                    }

                    #last_name {
                        animation: SenderLastName-move 1.5s linear;
                    }

                    @keyframes SenderLastName-move {
                        0% {
                            top: -550px;
                        }

                        90% {
                            left: 0px;
                            top: 0px;
                        }

                        95% {
                            transform: scale(0);
                        }

                        100% {
                            transform: scale(1);
                        }
                    }

                    #email {
                        animation: SenderEmail-move 1.3s linear;
                    }

                    @keyframes SenderEmail-move {
                        0% {
                            top: -550px;
                        }

                        90% {
                            left: 0px;
                            top: 0px;
                        }

                        95% {
                            transform: scale(0);
                        }

                        100% {
                            transform: scale(1);
                        }
                    }

                    #phone {
                        animation: SenderPhone-move 1.1s linear;
                    }

                    @keyframes SenderPhone-move {
                        0% {
                            top: -550px;
                        }

                        90% {
                            left: 0px;
                            top: 0px;
                        }

                        95% {
                            transform: scale(0);
                        }

                        100% {
                            transform: scale(1);
                        }
                    }

                    textarea {
                        text-align: left;
                        min-height: 90px;
                        font-size: 16px;
                        line-height: 1;
                        text-indent: 30px;
                        z-index: 1;
                        animation: SenderMessage-move .9s linear;
                    }

                    @keyframes SenderMessage-move {
                        0% {
                            top: -550px;
                        }

                        90% {
                            left: 0px;
                            top: 0px;
                        }

                        95% {
                            transform: scale(0);
                        }

                        100% {
                            transform: scale(1);
                        }
                    }
                }

                .contact-text,
                #first_name,
                #last_name,
                #email,
                #phone,
                textarea,
                button.btn {
                    display: block;
                    position: relative;
                    width: 90%;
                    height: 40px;
                    margin: 5px auto;
                    border-radius: 5px;
                    text-align: center;
                    line-height: 40px;
                    font-size: 16px;
                }

                .send-contact-form {
                    margin: 20px auto !important;
                    padding: 0;
                    font-weight: bold;
                    background-color: rgba(0, 0, 0, .9);
                    color: #fff;
                    border: 1px solid #fff;
                    transition: background-color .3s, color .3s;
                    animation: Contact-btn-move .7s linear;

                    &:hover {
                        background-color: rgba(0, 204, 0, .9);
                        color: #000;
                        border: 1px solid #000;
                        cursor: pointer;
                    }

                    &:active {
                        top: 2px;
                        left: 2px;
                    }
                }

                @keyframes Contact-btn-move {
                    0% {
                        top: -550px;
                    }

                    90% {
                        left: 0px;
                        top: 0px;
                    }

                    95% {
                        transform: scale(0);
                    }

                    100% {
                        transform: scale(1);
                    }
                }
            }
        }
    }
}

/**************************************
            @media Queries
**************************************/

@media all and (min-width: 576px) {
    .row-contact {
        display: flex;
        justify-content: space-around;
    }

    .row-contact>.contact-holder {
        width: 400px;
    }

    #contact-form>.img-holder>img {
        margin: 40px auto;
    }

    .matrix-holder {
        display: flex;
        flex-grow: 1;
        justify-content: center;
    }

    .matrix2,
    .matrix3,
    .matrix4,
    .matrix5,
    .matrix6,
    .matrix7,
    .matrix8,
    .matrix9,
    .matrix12,
    .matrix13,
    .matrix14,
    .matrix15,
    .matrix16,
    .matrix17,
    .matrix18,
    .matrix19 {
        display: none;
    }

    .matrix1,
    .matrix11 {
        width: 10px;
        height: 100%;
        align-self: center;
        background-image: url(../img/matrix.png);
        background-size: auto;
        position: relative;
    }

    .matrix1 {
        animation: matrix1 60s linear infinite;
    }

    @keyframes matrix1 {
        0% {
            background-position: 305px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 305px 1224px;
            left: 0;
            bottom: 0;
        }
    }

    .matrix11 {
        animation: matrix11 80s linear infinite;
    }

    @keyframes matrix11 {
        0% {
            background-position: 338px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 338px 1224px;
            left: 0;
            bottom: 0;
        }
    }

    #contact-form>.form-group>label.error {
        background-color: rgba(0, 0, 0, .5);
    }
}

@media all and (min-width: 768px) {
    .contact-page {
        height: 86vh;
    }

    .matrix-holder {
        justify-content: space-around;
    }

    .matrix2,
    .matrix3,
    .matrix12,
    .matrix13 {
        display: block;
        width: 10px;
        height: 100%;
        align-self: center;
        background-image: url(../img/matrix.png);
        background-size: auto;
        position: relative;
    }

    .matrix2 {
        animation: matrix2 65s linear infinite;
    }

    @keyframes matrix2 {
        0% {
            background-position: 352px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 352px 1224px;
            left: 0;
            bottom: 0;
        }
    }

    .matrix3 {
        animation: matrix3 55s linear infinite;
    }

    @keyframes matrix3 {
        0% {
            background-position: 370px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 370px 1224px;
            left: 0;
            bottom: 0;
        }
    }

    .matrix12 {
        animation: matrix12 75s linear infinite;
    }

    @keyframes matrix12 {
        0% {
            background-position: 369px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 369px 1224px;
            left: 0;
            bottom: 0;
        }
    }

    .matrix13 {
        animation: matrix13 85s linear infinite;
    }

    @keyframes matrix13 {
        0% {
            background-position: 256px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 256px 1224px;
            left: 0;
            bottom: 0;
        }
    }

}

@media all and (min-width: 992px) {

    .matrix4,
    .matrix5,
    .matrix14,
    .matrix15 {
        display: block;
        width: 10px;
        height: 100%;
        align-self: center;
        background-image: url(../img/matrix.png);
        background-size: auto;
        position: relative;
    }

    .matrix4 {
        animation: matrix4 73s linear infinite;
    }

    @keyframes matrix4 {
        0% {
            background-position: 145px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 145px 1224px;
            left: 0;
            bottom: 0;
        }
    }

    .matrix5 {
        animation: matrix5 68s linear infinite;
    }

    @keyframes matrix5 {
        0% {
            background-position: 113px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 113px 1224px;
            left: 0;
            bottom: 0;
        }
    }

    .matrix14 {
        animation: matrix14 104s linear infinite;
    }

    @keyframes matrix14 {
        0% {
            background-position: 402px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 402px 1224px;
            left: 0;
            bottom: 0;
        }
    }

    .matrix15 {
        animation: matrix15 54s linear infinite;
    }

    @keyframes matrix15 {
        0% {
            background-position: 450px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 450px 1224px;
            left: 0;
            bottom: 0;
        }
    }
}

@media all and (min-width: 1200px) {

    .matrix6,
    .matrix7,
    .matrix16,
    .matrix17 {
        display: block;
        width: 10px;
        height: 100%;
        align-self: center;
        background-image: url(../img/matrix.png);
        background-size: auto;
        position: relative;
    }

    .matrix6 {
        animation: matrix6 79s linear infinite;
    }

    @keyframes matrix6 {
        0% {
            background-position: 291px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 291px 1224px;
            left: 0;
            bottom: 0;
        }
    }

    .matrix7 {
        animation: matrix7 58s linear infinite;
    }

    @keyframes matrix7 {
        0% {
            background-position: 386px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 386px 1224px;
            left: 0;
            bottom: 0;
        }
    }

    .matrix16 {
        animation: matrix16 81s linear infinite;
    }

    @keyframes matrix16 {
        0% {
            background-position: 307px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 307px 1224px;
            left: 0;
            bottom: 0;
        }
    }

    .matrix17 {
        animation: matrix17 66s linear infinite;
    }

    @keyframes matrix17 {
        0% {
            background-position: 339px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 339px 1224px;
            left: 0;
            bottom: 0;
        }
    }
}

@media all and (min-width: 1400px) {

    .matrix8,
    .matrix9,
    .matrix18,
    .matrix19 {
        display: block;
        width: 10px;
        height: 100%;
        align-self: center;
        background-image: url(../img/matrix.png);
        background-size: auto;
        position: relative;
    }

    .matrix8 {
        animation: matrix8 67s linear infinite;
    }

    @keyframes matrix8 {
        0% {
            background-position: 225px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 225px 1224px;
            left: 0;
            bottom: 0;
        }
    }

    .matrix9 {
        animation: matrix9 115s linear infinite;
    }

    @keyframes matrix9 {
        0% {
            background-position: 241px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 241px 1224px;
            left: 0;
            bottom: 0;
        }
    }

    .matrix18 {
        animation: matrix18 57s linear infinite;
    }

    @keyframes matrix18 {
        0% {
            background-position: 482px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 482px 1224px;
            left: 0;
            bottom: 0;
        }
    }

    .matrix19 {
        animation: matrix19 87s linear infinite;
    }

    @keyframes matrix19 {
        0% {
            background-position: 241px 0;
            top: 0;
            left: 0;
        }

        100% {
            background-position: 241px 1224px;
            left: 0;
            bottom: 0;
        }
    }
}