/* About-me page */
.about-me {
    #language {
        cursor: pointer;
        position: absolute;
        top: 110px;
        right: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        z-index: 3;

        &:active {
            transform: scale(.9);
        }

        .srb,
        .en {
            display: block;
            width: 100%;
            height: 100%;
            background: url(../img/language-sprites.png);
        }

        &>a.srb {
            background-position: 0 0;
        }

        &>a.en {
            background-position: 40px 0;
        }
    }

    .column-about {
        position: relative;
        width: 96%;
        margin: 100px auto 0px;

        a {
            position: relative;
            z-index: 1;
        }
    }

    #bubbles {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        ul {
            margin: 0;
            padding: 0;
        }

        .circles {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;

            .column-about-img-holder {
                width: 100%;
                height: 100%;

                a {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }

            li {
                position: absolute;
                display: block;
                list-style: none;
                width: 20px;
                height: 20px;
                background: rgba(255, 255, 255, 0.1);
                animation: animate 25s linear infinite;
                bottom: -150px;
            }

            li:nth-child(1) {
                left: 25%;
                width: 80px;
                height: 80px;
                animation-delay: 0s;
            }

            li:nth-child(2) {
                left: 10%;
                width: 30px;
                height: 30px;
                animation-delay: 2s;
                animation-duration: 12s;
            }

            li:nth-child(3) {
                left: 70%;
                width: 30px;
                height: 30px;
                animation-delay: 4s;
            }

            li:nth-child(4) {
                left: 40%;
                width: 60px;
                height: 60px;
                animation-delay: 0s;
                animation-duration: 18s;
            }

            li:nth-child(5) {
                left: 65%;
                width: 30px;
                height: 30px;
                animation-delay: 0s;
            }

            li:nth-child(6) {
                left: 75%;
                width: 100px;
                height: 100px;
                animation-delay: 3s;
                overflow: hidden;
            }

            li:nth-child(7) {
                left: 35%;
                width: 120px;
                height: 120px;
                animation-delay: 7s;
            }

            li:nth-child(8) {
                left: 50%;
                width: 35px;
                height: 35px;
                animation-delay: 15s;
                animation-duration: 45s;
            }

            li:nth-child(9) {
                left: 20%;
                width: 25px;
                height: 25px;
                animation-delay: 2s;
                animation-duration: 35s;
            }

            li:nth-child(10) {
                left: 85%;
                width: 120px;
                height: 120px;
                animation-delay: 0s;
                animation-duration: 11s;
            }

            @keyframes animate {

                0% {
                    transform: translateY(0) rotate(0deg);
                    opacity: 1;
                    border: 0px solid #333;
                    border-radius: 0;
                }

                90% {
                    border-radius: 50%;
                }

                100% {
                    transform: translateY(-2250px) rotate(720deg);
                    opacity: 0.03;
                    border: 5px solid #888;
                    border-radius: 50%;
                }

            }
        }
    }
}

/************ Media ************/
@media all and (min-width: 576px) {
    #About_Me {
        .column-about {
            width: 80%;
        }

        #bubbles {
            .circles {
                @keyframes animate {

                    0% {
                        transform: translateY(0) rotate(0deg);
                        opacity: 1;
                        border: 0px solid #333;
                        border-radius: 0;
                    }

                    90% {
                        border-radius: 50%;
                    }

                    100% {
                        transform: translateY(-2000px) rotate(720deg);
                        opacity: 0.03;
                        border: 5px solid #888;
                        border-radius: 50%;
                    }

                }
            }
        }
    }
}

@media all and (min-width: 768px) {
    #About_Me {
        #language {
            top: 140px;
        }

        #bubbles {
            .circles {
                @keyframes animate {

                    0% {
                        transform: translateY(0) rotate(0deg);
                        opacity: 1;
                        border: 0px solid #333;
                        border-radius: 0;
                    }

                    90% {
                        border-radius: 50%;
                    }

                    100% {
                        transform: translateY(-1600px) rotate(720deg);
                        opacity: 0.03;
                        border: 5px solid #888;
                        border-radius: 50%;
                    }

                }
            }
        }
    }
}

@media all and (min-width: 992px) {
    #About_Me {
        #bubbles {
            .circles {
                @keyframes animate {

                    0% {
                        transform: translateY(0) rotate(0deg);
                        opacity: 1;
                        border: 0px solid #333;
                        border-radius: 0;
                    }

                    90% {
                        border-radius: 50%;
                    }

                    100% {
                        transform: translateY(-1300px) rotate(720deg);
                        opacity: 0.03;
                        border: 5px solid #888;
                        border-radius: 50%;
                    }

                }
            }
        }
    }
}

@media all and (min-width: 1200px) {
    #About_Me {
        #bubbles {
            .circles {
                @keyframes animate {

                    0% {
                        transform: translateY(0) rotate(0deg);
                        opacity: 1;
                        border: 0px solid #333;
                        border-radius: 0;
                    }

                    90% {
                        border-radius: 50%;
                    }

                    100% {
                        transform: translateY(-1200px) rotate(720deg);
                        opacity: 0.03;
                        border: 5px solid #888;
                        border-radius: 50%;
                    }

                }
            }
        }
    }
}

@media all and (min-width: 1400px) {
    #About_Me {
        #bubbles {
            .circles {
                @keyframes animate {

                    0% {
                        transform: translateY(0) rotate(0deg);
                        opacity: 1;
                        border: 0px solid #333;
                        border-radius: 0;
                    }

                    90% {
                        border-radius: 50%;
                    }

                    100% {
                        transform: translateY(-1100px) rotate(720deg);
                        opacity: 0.03;
                        border: 5px solid #888;
                        border-radius: 50%;
                    }

                }
            }
        }
    }
}

@media all and (min-width: 1600px) {
    #About_Me {
        #bubbles {
            .circles {
                @keyframes animate {

                    0% {
                        transform: translateY(0) rotate(0deg);
                        opacity: 1;
                        border: 0px solid #333;
                        border-radius: 0;
                    }

                    90% {
                        border-radius: 50%;
                    }

                    100% {
                        transform: translateY(-1000px) rotate(720deg);
                        opacity: 0.03;
                        border: 5px solid #888;
                        border-radius: 50%;
                    }

                }
            }
        }
    }
}