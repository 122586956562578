$space-color1: rgba(0, 0, 0, .97);
$planet-color1: rgb(75, 75, 75);
$planet-color2: darken($planet-color1, 7%);
$planet-color3: darken($planet-color2, 7%);
$ring-color1: rgb(64, 64, 64);
$ring-color2: darken($ring-color1, 7%);
$font-color: #FF8000;

.error-page {
    height: 92vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-family: 'Ubuntu', sans-serif;

    .planet {
        transform-style: preserve-3d;
        position: relative;

        &:after,
        &:before {
            content: "";
            position: absolute;
            background: linear-gradient(to top, transparent, rgba(255, 255, 255, 0.05));
            border-radius: 50%;
            transform: translateZ(-40em);
        }

        &:after {
            top: calc(50% - 7em);
            left: calc(50% - 7em);
            width: 14em;
            height: 14em;
            animation-delay: 2s;
        }

        &:before {
            top: calc(50% - 9em);
            left: calc(50% - 9em);
            width: 18em;
            height: 18em;
        }

        .inner {
            transform: rotate3d(0, 0, 1, -30deg) translateZ(1em);
            position: absolute;
            top: calc(50% - 5em);
            left: calc(50% - 5em);
            width: 10em;
            height: 10em;
            background: linear-gradient(90deg, $planet-color3, $planet-color1);
            border-radius: 50%;
            overflow: hidden;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: radial-gradient(transparent 66%, rgba(0, 0, 0, 0.1) 66.1%)
            }
        }

        .ring {
            position: absolute;
            top: calc(50% - 9em);
            left: calc(50% - 9em);
            width: 18em;
            height: 18em;
            border-radius: 50%;
            background: radial-gradient(transparent 50%,
                    $ring-color2 50.1%,
                    $ring-color2 60%,
                    $ring-color1 60.1%);
            transform: rotateX(78deg) rotateY(-30deg) translateY(-3.125px);
        }

        .moon {
            position: absolute;
            top: -16em;
            left: 6em;
            width: 3em;
            height: 3em;
            background: radial-gradient(at bottom left,
                    $planet-color3 50%,
                    $planet-color2 50.1%,
                    $planet-color2 70%, $planet-color1 70.1%);
            border-radius: 50%;
            overflow: hidden;
            transform: translateZ(22em);
            animation: moon 7s linear infinite;

            &:after,
            &:before {
                content: "";
                position: absolute;
            }

            &:after {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: radial-gradient(transparent 64%, rgba(0, 0, 0, 0.1) 64.1%)
            }

            &:before {
                top: 2.5em;
                left: 2.2em;
                width: 1em;
                height: 1em;
                background-color: $planet-color3;
                border-radius: 50%;
            }
        }

        .error-span {
            font-size: 14em;
            font-weight: bold;
            position: absolute;
            top: 0;
            transform: translateY(-50%);

            &:nth-of-type(1) {
                right: 0.55em;
                color: $planet-color2;
            }

            &:nth-of-type(2) {
                left: 0.50em;
                color: $planet-color1;
            }

            @media(max-width: 460px) {
                display: none;
            }
        }

        .stars,
        .stars:after,
        .stars:before {
            position: fixed;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            background-color: $font-color;
            animation: blink 5s infinite ease-in-out;
        }

        .stars {

            &:after,
            &:before {
                content: "";
                display: block;
            }
        }

        .s1 {
            top: -19em;
            left: -9em;

            &:after {
                animation-delay: 0.5s;
                top: 13em;
                left: 8em;
            }

            &:before {
                top: 18em;
                left: -10em;
            }
        }

        .s2 {
            animation-delay: .25s;
            top: -30em;
            left: 8em;
            font-size: 0.6em;

            &:after {
                top: 20em;
                left: 3em;
            }

            &:before {
                animation-delay: 0.5s;
                top: 12em;
                left: -15em;
            }
        }

        .s3 {
            animation-delay: .5s;
            top: -27em;
            left: 1em;
            font-size: 0.5em;

            &:after {
                animation-delay: 0.75s;
                top: -48em;
                left: -4em;
            }

            &:before {
                animation-delay: .25s;
                top: 35em;
                left: 12em;
            }
        }

        .s4 {
            top: -30em;
            left: -30em;
            font-size: 0.3em;

            &:after {
                animation-delay: .5s;
                top: -20em;
                left: 30em;
            }

            &:before {
                top: 25em;
                left: 22em;
            }
        }

        @keyframes blink {
            0% {
                opacity: .85;
            }

            25% {
                opacity: 0.4;
            }

            50% {
                opacity: .85;
            }

            75% {
                opacity: 0.4;
            }

            100% {
                opacity: .85;
            }
        }

        @keyframes moon {
            0% {
                top: 5em;
                left: -5em;
                transform: translateZ(-30em);
            }

            1% {
                transform: translateZ(30em);
            }

            50% {
                top: -8em;
                left: 3em;
                transform: translateZ(30em);
            }

            51% {
                transform: translateZ(-30em);
            }

            60% {
                transform: scale(0.8);
            }

            95% {
                transform: scale(0.8);
            }

            100% {
                top: 5em;
                left: -5em;
                transform: translateZ(-30em);
            }
        }
    }

    .alt {
        position: fixed;
        bottom: 1.25em;
        font-size: 3em;
        font-weight: bold;
        margin: 0;
        letter-spacing: 0.2em;

        @media(min-width: 461px) {
            display: none;
        }
    }

    .info {
        position: fixed;
        bottom: 1.8em;
        font-size: 1.5em;
        font-weight: bold;
        margin: 0;
        letter-spacing: 0.2em;

        @media(max-width: 460px) {
            bottom: 1em;
        }
    }
}