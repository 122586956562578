.user-account-page {
    .account-img-holder {
        img {
            max-width: 80px;
        }
    }

    .user-holder {
        max-width: 400px;
        margin: auto;

        label {
            display: block;
            text-align: left;
        }

        input {
            color: var(--gold);
            background-color: rgba(0, 0, 0, .1);
            border-color: var(--grey);

            &:focus {
                color: var(--white);
                background-color: rgba(0, 0, 0, .1);
                border-color: transparent !important;
                box-shadow: 0 0 0 0.25rem rgba(255, 128, 0, .25);
                ;
            }

            &:focus-visible {
                border-color: var(--gold);
                outline: none;
            }

            &::selection {
                color: var(--gold);
                background-color: rgba(0, 0, 0, .1);
            }

            &::placeholder {
                color: #6c757d;
            }
        }

        .btn.btn-send {
            color: var(--black);
            background-color: var(--gold);
        }
    }

    .btn-holder {
        max-width: 400px;
        margin: auto;
    }
}