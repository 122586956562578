@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400&display=swap');

/* font-family: 'PT Sans', sans-serif; */
/*   random images:      https://source.unsplash.com/random    */
/**********************************************
                Global styles
**********************************************/
*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

:root {
  --white: #ffffff;
  --silver: #cccccc;
  --grey: #333333;
  --black-def: #0a0a0a;
  --black: #000000;
  --blue: #0d6efd;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #d63384;
  --red: #dc3545;
  --brown: #663300;
  --orange: #fd7e14;
  --gold: #FF8000;
  --gold-sec: #ff9900;
  --yellow: #ffc107;
  --matrix-green: #00cc00;
  --green: #198754;
  --teal: #20c997;
  --cyan: #0dcaf0;
  --font-family: 'PT Sans', sans-serif;
  --font-size: 1rem;
  --font-weight: 400;
  --line-height: 1.5;
  --body-color: var(--black);
  --body-bg: var(--white);
  --gutter-x: 1.5rem;
  --gutter-y: 0;
  --breakpoint-576: 576px;
  --breakpoint-768: 768px;
  --breakpoint-992: 992px;
  --breakpoint-1200: 1200px;
  --breakpoint-1400: 1400px;
}

a {
  text-decoration: none;
  color: var(--gold);

  &:hover {
    color: var(--white);
  }
}

ul li {
  list-style: none;
}

small {
  font-size: 12px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family) !important;
}

p {
  font-family: var(--font-family) !important;
}

body {
  position: relative;
  min-height: 100vh;
  padding-bottom: 50px;
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  background-image: url(../src/assets/img/circuit-board.svg);
  background-repeat: repeat;
  background-position: top center;
  background-attachment: fixed;
  background-size: auto;
  color: var(--gold);
  background-color: var(--black-def);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

/**********************************************
                    Layout
**********************************************/
.pagination-holder {
  position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 38.21px;
  ul.pagination>li.disabled>button.page-link {
    color: var(--grey);
    background-color: rgba(0, 0, 0, .1);
    border-color: var(--grey);

    &:focus {
      box-shadow: 0 0 0 0.10rem rgba(204, 204, 204, 0.25);
    }
  }

  ul.pagination>li.active>button.page-link {
    color: var(--black);
    background-color: var(--gold);
    border-color: initial;

    &:focus {
      box-shadow: 0 0 0 0.10rem rgba(204, 204, 204, 0.25);
    }
  }

  ul.pagination>li>button.page-link {
    color: var(--silver);
    background-color: rgba(0, 0, 0, .8);
    border-color: var(--grey);

    &:focus {
      box-shadow: 0 0 0 0.10rem rgba(204, 204, 204, 0.25);
    }
  }
}

.play-btn {
  position: absolute;
  bottom: 6px;
  left: 6px;
}

/**********************************************
                Specific styles
**********************************************/

/**********************************************
                Helper classes
**********************************************/
.list-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}

.clearfix:after {
  clear: both;
}

/*********************************************
               @media Queries
*********************************************/
@media print {
  .print-hide {
    display: none !important;
  }
}

/*********************************************
               Imports SCSS
*********************************************/

// @import 'assets/scss/loader.scss';
@import 'assets/scss/error.scss';
@import 'assets/scss/nav.scss';
@import 'assets/scss/home.scss';
@import 'assets/scss/about-me.scss';
@import 'assets/scss/skills.scss';
@import 'assets/scss/skill-details.scss';
@import 'assets/scss/projects.scss';
@import 'assets/scss/contact.scss';
@import 'assets/scss/verification.scss';
@import 'assets/scss/comments.scss';
@import 'assets/scss/google.scss';
@import 'assets/scss/email.scss';
@import 'assets/scss/forgottenPassword.scss';
@import 'assets/scss/resetPassword.scss';
@import 'assets/scss/register.scss';
@import 'assets/scss/confirm-register.scss';
@import 'assets/scss/impression.scss';
@import 'assets/scss/user-acc.scss';
@import 'assets/scss/cv.scss';
@import 'assets/scss/footer.scss';
@import 'assets/scss/loader.scss';
@import 'assets/scss/modal.scss';