.google-page {
    &-holder {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 207px;
        margin: 100px auto 20px;
    }
    .btn {
        width: 100px;
        display: block;
        margin: auto;
    }
}